import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route } from 'react-router';
import Layout from './Layout';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Faq from './Faq';
import Products from './Products';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout><Home /></Layout>}/>
          <Route path='/about' element={<Layout><About /></Layout>}/>
          <Route path='/contact' element={<Layout><Contact /></Layout>}/>
          <Route path='/faq' element={<Layout><Faq /></Layout>}/>
          <Route path='/products' element={<Layout><Products /></Layout>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
