import evanMcdougal from './assets/images/product/evan-mcdougall-qnh1odlqOmk-unsplash.jpeg';
import jordanNix from './assets/images/product/jordan-nix-CkCUvwMXAac-unsplash.jpeg';
import natureZen from './assets/images/product/nature-zen-3Dn1BZZv3m8-unsplash.jpeg';
import teamFredi from './assets/images/product/team-fredi-8HRKoay8VJE-unsplash.jpeg';
import quokkaBottles from './assets/images/product/quokkabottles-kFc1_G1GvKA-unsplash.jpeg';
import anisM from './assets/images/product/anis-m-WnVrO-DvxcE-unsplash.jpeg';

const Products = () => {
    return (
        <>
            <header className="site-header section-padding d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-10 col-12">
                            <h1>
                                <span className="d-block text-primary">Choose your</span>
                                <span className="d-block text-dark">favorite stuffs</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>

            <section className="products section-padding">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-12">
                            <h2 className="mb-5">New Arrivals</h2>
                        </div>

                        <div className="col-lg-4 col-12 mb-3">
                            <div className="product-thumb">
                                <a href="product-detail.html">
                                    <img src={evanMcdougal} className="img-fluid product-image" alt="" />
                                </a>

                                <div className="product-top d-flex">
                                    <span className="product-alert me-auto">New Arrival</span>

                                    <a href="#" className="bi-heart-fill product-icon"></a>
                                </div>

                                <div className="product-info d-flex">
                                    <div>
                                        <h5 className="product-title mb-0">
                                            <a href="product-detail.html" className="product-title-link">Tree pot</a>
                                        </h5>

                                        <p className="product-p">Original package design from house</p>
                                    </div>

                                    <small className="product-price text-muted ms-auto">$25</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mb-3">
                            <div className="product-thumb">
                                <a href="product-detail.html">
                                    <img src={jordanNix} className="img-fluid product-image" alt="" />
                                </a>

                                <div className="product-top d-flex">
                                    <span className="product-alert">Discounted Price</span>

                                    <a href="#" className="bi-heart-fill product-icon ms-auto"></a>
                                </div>

                                <div className="product-info d-flex">
                                    <div>
                                        <h5 className="product-title mb-0">
                                            <a href="product-detail.html" className="product-title-link">Fashion set</a>
                                        </h5>

                                        <p className="product-p">Costume package</p>
                                    </div>

                                    <small className="product-price text-muted ms-auto">$35</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-12">
                            <div className="product-thumb">
                                <a href="product-detail.html">
                                    <img src={natureZen} className="img-fluid product-image" alt="" />
                                </a>

                                <div className="product-top d-flex">
                                    <a href="#" className="bi-heart-fill product-icon ms-auto"></a>
                                </div>

                                <div className="product-info d-flex">
                                    <div>
                                        <h5 className="product-title mb-0">
                                            <a href="product-detail.html" className="product-title-link">Juice Drinks</a>
                                        </h5>

                                        <p className="product-p">Nature made another world</p>
                                    </div>

                                    <small className="product-price text-muted ms-auto">$45</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <h2 className="mb-5">Popular</h2>
                        </div>

                        <div className="col-lg-4 col-12 mb-3">
                            <div className="product-thumb">
                                <a href="product-detail.html">
                                    <img src={teamFredi} className="img-fluid product-image" alt="" />
                                </a>

                                <div className="product-top d-flex">
                                    <span className="product-alert">Trending</span>

                                    <a href="#" className="bi-heart-fill product-icon ms-auto"></a>
                                </div>

                                <div className="product-info d-flex">
                                    <div>
                                        <h5 className="product-title mb-0">
                                            <a href="product-detail.html" className="product-title-link">Package</a>
                                        </h5>

                                        <p className="product-p">Original package design from house</p>
                                    </div>

                                    <small className="product-price text-muted ms-auto">$50</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mb-3">
                            <div className="product-thumb">
                                <a href="product-detail.html">
                                    <img src={quokkaBottles} className="img-fluid product-image" alt="" />
                                </a>

                                <div className="product-top d-flex">
                                    <a href="#" className="bi-heart-fill product-icon ms-auto"></a>
                                </div>

                                <div className="product-info d-flex">
                                    <div>
                                        <h5 className="product-title mb-0">
                                            <a href="product-detail.html" className="product-title-link">Bottle</a>
                                        </h5>

                                        <p className="product-p">Package design</p>
                                    </div>

                                    <small className="product-price text-muted ms-auto">$100</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mb-3">
                            <div className="product-thumb">
                                <a href="product-detail.html">
                                    <img src={anisM} className="img-fluid product-image" alt="" />
                                </a>

                                <div className="product-top d-flex">
                                    <a href="#" className="bi-heart-fill product-icon ms-auto"></a>
                                </div>

                                <div className="product-info d-flex">
                                    <div>
                                        <h5 className="product-title mb-0">
                                            <a href="product-detail.html" className="product-title-link">Medicine</a>
                                        </h5>

                                        <p className="product-p">Original design from house</p>
                                    </div>

                                    <small className="product-price text-muted ms-auto">$200</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Products;