import { Link, useLocation } from "react-router-dom";

const Header = () => {
    const location = useLocation();
    const {pathname} = location;
    
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <Link className="navbar-brand" to="/">
                    <strong><span>Little</span> Fashion</strong>
                </Link>

                <div className="d-lg-none">
                    <a href="sign-in.html" className="bi-person custom-icon me-3"></a>

                    <a href="product-detail.html" className="bi-bag custom-icon"></a>
                </div>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <Link className={`nav-link ${pathname == '/' || pathname == '/home' ? 'active' : ''}`} to="/">Home</Link>
                        </li>

                        <li className="nav-item">
                            <Link className={`nav-link ${pathname == '/about' ? 'active' : ''}`} to="/about">Story</Link>
                        </li>

                        <li className="nav-item">
                            <Link className={`nav-link ${pathname == '/products' ? 'active' : ''}`} to="/products">Products</Link>
                        </li>

                        <li className="nav-item">
                            <Link className={`nav-link ${pathname == '/faq' ? 'active' : ''}`} to="/faq">FAQs</Link>
                        </li>

                        <li className="nav-item">
                            <Link className={`nav-link ${pathname == '/contact' ? 'active' : ''}`} to="/contact">Contact</Link>
                        </li>
                    </ul>

                    <div className="d-none d-lg-block">
                        <a href="sign-in.html" className="bi-person custom-icon me-3"></a>

                        <a href="product-detail.html" className="bi-bag custom-icon"></a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;