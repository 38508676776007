import './styles/slick.css';
import './styles/bootstrap.min.css';
import './styles/bootstrap-icons.css';
import './styles/tooplate-little-fashion.css';

import Header from './Header';
import Footer from './Footer';
import { useEffect, useState } from 'react';

const Layout = (props) => {
    const [showPreLoader, setShowPreLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setShowPreLoader(false);
        }, 500)
    }, [])

    return (
        <>
            {showPreLoader && <section className="preloader" >
                <div className="spinner">
                    <span className="sk-inner-circle"></span>
                </div>
            </section>}

            <main>
                <Header />
                {props.children}
            </main>

            <Footer />
        </>
    )
}

export default Layout;